// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-stb-upload-js": () => import("./../../../src/pages/stb-upload.js" /* webpackChunkName: "component---src-pages-stb-upload-js" */),
  "component---src-pages-turtles-js": () => import("./../../../src/pages/turtles.js" /* webpackChunkName: "component---src-pages-turtles-js" */)
}

